<template>
    <BlackRabbitModal
        class="store-suggestion"
        :title="title"
        @close="onClose"
    >
        <template v-slot:content>
            <div class="ss-body" v-html="message">
            </div>
            <div class="ss-footer">
                <a :href="goUrl" data-mub-skip="y">{{ goText }}</a>
            </div>
        </template>
    </BlackRabbitModal>
</template>
<script>
import BlackRabbitModal from '@/components/BlackRabbit/ModalDesktop.vue';

export default {
    components: {
        BlackRabbitModal,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        message: {
            type: String,
            default: null,
        },
        goText: {
            type: String,
            default: null,
        },
        goUrl: {
            type: String,
            default: null,
        },
        cookie: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
        };
    },
    computed: {
    },
    methods: {
        onClose(){
            if(this.cookie){
                let d = new Date();
                d.setDate(d.getDate() + 90); // valid for 90 days
                document.cookie = this.cookie.name + "=" + this.cookie.value + ";expires=" + d.toUTCString() + ";domain=" + this.cookie.domain + ";path=/"; 
            }
            this.$destroy();
        }
    },
    beforeDestroy () {
        this.$root.$el.parentNode.removeChild(this.$root.$el);
    },  
    created(){
    }
};
</script>
<style lang="scss">
@import "./styles/index.scss";
</style>
