<template>
    <div class="b-rabbit-modal-bg">
        <section class="b-rabbit-modal">
            <slot name="header">
                <div v-if="showHeader && !showPromoterHeader" class="modal-header-box">
                    <h2 class="modal-header-title" v-html="title"></h2>
                    <i class="prz-close" @click="close" />
                </div>
                <div v-if="showPromoterHeader && !showHeader" class="promoter-header-box">
                    <div class="modal-header-box">
                        <h2 class="modal-header-title" v-html="title"></h2>
                        <i class="prz-close" @click="close" />
                    </div>   
                    <div class="promoter-header-box-content">
                        <p class="campaign-copy">{{kStrings.monthCampaign}}:</p>
                        <p v-if="couponCode" class="coupon">{{kStrings.couponCode}}: <span class="coupon-code">{{couponCode}}</span></p>
                    </div>
                </div>

            </slot>
            <div class="modal-content-box">
                <slot name="content" />
            </div>
            <div v-if="showFooter" class="modal-footer-box">
                <slot name="footer" />
            </div>
        </section>
    </div>
</template>
<script>

export default {
    components: {
    },
    props: {
        showHeader:{
            type: Boolean,
            default: true,
        },
        showPromoterHeader:{
            type: Boolean,
            default: false,
        },
        showFooter:{
            type: Boolean,
            default: false,
        },
        title:{
            type: String,
            default: '',
        },
        couponCode:{
            type: String,
            default: '',
        },
        kStrings:{
            type: Object,
            default: {},
        }
    },
    data() {
        return {
        };
    },
    computed: {
    },
    methods: {
        close(){
            this.$emit('close');
        }
    },
    //watch: {},
    created(){

    }
};
</script>
<style lang="scss">
@import "../../scss/CalcSize.scss";
.b-rabbit-modal-bg{
    position: fixed;
    top:0;
    left: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 202;
    background: rgba(0, 0, 0, 0.60);
    backdrop-filter: blur(20px);

    .b-rabbit-modal{
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-color: var(--colors-shades-of-grey-2, #F8F8F8);
        width: 444px;
        min-height: 444px;
        padding: responsive(20px);
        margin: 14vh 0;
    }

    .modal-header-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom:responsive(20px);

        .modal-header-title{
            display: flex;
            color: #333;
            font-size: responsive(38px);
            font-weight: 600;
            line-height: responsive(46px);
            padding: 0 !important;
            margin:0 !important;
        }

        i.prz-close {
            width: responsive(40px);
            height: responsive(40px);
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            color: #333;
            font-size: responsive(24px);
            border-radius: 50%;
            box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.30);
            cursor: pointer;
            padding: responsive(8px);
        }        
    }

    .modal-content-box{
        flex-grow:1;
    }
}


@media screen and (min-width: $bkp-m-start) and (max-width: $bkp-m-end){
    .b-rabbit-modal-bg{
        .modal-header-box{
            margin-bottom: responsive(12px);
        }
        .modal-header-box .modal-header-title{
            font-size: responsive(30px);
            line-height: responsive(36px);
        }
    }
}

@media screen and (max-width: $bkp-s-end){
    .b-rabbit-modal-bg{
        .modal-header-box{
            margin-bottom: responsive(16px);
        }
        .modal-header-box .modal-header-title{
            font-size: responsive(24px);
            line-height: responsive(28px);
        }
    }    
}
</style>